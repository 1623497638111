import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styles from "../styles/home.module.scss"
import downArrow from "../images/logos/arrow.svg"
import HomeSlide from "./homeSlides"
import Slider from "react-slick"
import PrevArrow from "./homeArrows/prevArrow"
import NextArrow from "./homeArrows/nextArrow"
import { useMediaQuery } from "react-responsive"
import * as Scroll from "react-scroll"
let LinkTo = Scroll.Link

const settings = {
  dots: true,
  dotsClass: `slick-dots ${styles.dotsHome}`,
  infinite: true,
  speed: 3000,
  autoplay: true,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  swipeToSLide: true,
  arrows: true,
  swipe: true,
  touchMove: true,
  className: `${styles.mobileSlider}`,
  prevArrow: <NextArrow></NextArrow>,
  nextArrow: <PrevArrow></PrevArrow>,
}

//LOGICA SIMILAR PARA AGREGAR IMAGEN MOBILE DESDE LOCALIMAGE

function HomeComponent({ data }) {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [isActive, setIsActive] = useState(true)
  const slider = useRef(null)

  function handleClick() {
    setIsActive(!isActive)
  }

  let homeData = data ? data.allHome.nodes.sort(function (a, b) {
    return a.order - b.order
  }) : []

  let homeEdges = data ? data.allHome.edges.sort(function (a, b) {
    return a.node.order - b.node.order
  }) : []
  console.log("Home Data", homeData)
  let backImg = null
  const handleLink = (data) => {
    const link = data.slideHref
    if(link){
      window.location.href = link
    }
  }

  return (
    <div style={{ height: "100vh"}}>
      {homeData && homeData.length > 1 ? 
      (<>
        <Slider
          ref={slider}
          {...settings}
          style={{ height: "100vh !important", display: "inline" }}
        >
          {homeData &&
            homeData.map((slide, idx) => {
              if(homeEdges[slide.order]){
                backImg = !mobile
                ? homeEdges[slide.order].node.slide_image.childImageSharp.fluid : homeEdges[slide.order].node.slide_image_mobile.childImageSharp.fluid
              }
              console.log("BackImg", backImg)
              console.log("HomeEdges", homeEdges)
                return (
                <BackgroundImage
                  key={idx + 1000}
                  style={{ height: "100vh", width: "100vw" }}
                  fluid={backImg}
                  onClick={() => {
                    handleClick()
                    slider.current.slickPause()
                    // realiza la pausa tomando el useRef del Slider. Ver en homeSlides que está el slickPlay para que una vez cerrada la ventana vuelva el autoplay.
                  }}
                >
                  <div key={idx + 3000} className={styles.blackOverlay} onClick={()=>handleLink(slide)}>
                    {/* {slide.slide ? (
                      <HomeSlide
                        settings={settings}
                        key={idx}
                        isActive={isActive}
                        setIsActive={setIsActive}
                        slide={slide}
                        homeEdges={homeEdges}
                        slider={slider}
                      ></HomeSlide>
                    ) : null} */}

                    <div className={`${styles.container} ${styles.slideColText}`}>
                      <div className={styles.textContainer}>
                        <h1 className={styles.home130text}>
                          {slide.homeTitle.toUpperCase()}
                        </h1>
                      </div>

                      <div className={styles.homeText}>
                        <p>{slide.slideText}</p>

                        {/* {slide.slideButton === "true" ? (
                          // slide.slideHref ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              className={styles.homeButton}
                              to={`${slide.slideHref}`}
                              // href={`/${slide.homeHref}`}
                              // target="_blank"
                            >
                              {slide.slideButtonText.toUpperCase()}
                            </Link>
                          // ) : (
                            // <button
                            //   style={{ textDecoration: "none" }}
                            //   className={styles.homeButton}
                            //   onClick={() => {
                            //     handleClick()
                            //     slider.current.slickPause()
                            //     // realiza la pausa tomando el useRef del Slider. Ver en homeSlides que está el slickPlay para que una vez cerrada la ventana vuelva el autoplay.
                            //   }}
                            // >
                            //   {slide.slideButtonText.toUpperCase()}
                            // </button>
                        //   )
                        ) : 
                        null} */}
                      </div>
                    </div>

                    <div className={isActive ? styles.arrowMain : styles.hidden}>
                      <LinkTo to="varieties" smooth={true}>
                        <div id={styles.circle}>
                          <div id={styles.circleFixed}>
                            <img alt="flecha para ir abajo" src={downArrow} />{" "}
                          </div>
                        </div>
                      </LinkTo>
                    </div>
                  </div>
                </BackgroundImage>
              )
            })}
        </Slider>
      </>):(
        <>
            <BackgroundImage
                  style={{ height: "100vh", width: "100vw" }}
                  fluid={!mobile ? homeEdges[0].node.slide_image.childImageSharp.fluid : homeEdges[0].node.slide_image_mobile.childImageSharp.fluid}
                >
                    <div className={`${styles.container2} ${styles.slideColText}`}>
                      <div className={styles.textContainer}>
                        <h1 className={styles.home130text}>
                          {homeData[0].homeTitle.toUpperCase()}
                        </h1>
                      </div>

                      <div className={styles.homeText}>
                        <p>{homeData[0].homeText}</p>

                        {homeData[0].slideButton === "true" ? (
                          // homeData[0].slideHref ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              className={styles.homeButton}
                              to={`${homeData[0].slideHref}`}
                              // href={`/${homeData[0].homeHref}`}
                              // target="_blank"
                            >
                              {homeData[0].slideButtonText.toUpperCase()}
                            </Link>
                          // ) : (
                          //   <button
                          //     style={{ textDecoration: "none" }}
                          //     className={styles.homeButton}
                          //     onClick={() => {
                          //       handleClick()
                          //       slider.current.slickPause()
                          //       // realiza la pausa tomando el useRef del Slider. Ver en homeSlides que está el slickPlay para que una vez cerrada la ventana vuelva el autoplay.
                          //     }}
                          //   >
                          //     {homeData[0].slideButtonText.toUpperCase()}
                          //   </button>
                          // )
                        ) : null}
                      </div>
                    </div>

                    <div className={isActive ? styles.arrowMain : styles.hidden}>
                      <LinkTo to="varieties" smooth={true}>
                        <div id={styles.circle}>
                          <div id={styles.circleFixed}>
                            <img alt="flecha para ir abajo" src={downArrow} />{" "}
                          </div>
                        </div>
                      </LinkTo>
                    </div>
                  {/* </div> */}

                </BackgroundImage>
        </>
      )
      }
    </div>
  )
}

export default HomeComponent
